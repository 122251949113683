import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 50, // Increased width
        height: 26, // Decreased height
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 2,
        '&$checked': {
            transform: 'translateX(23px)', // Adjusted for new width
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#24a7e0',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#24a7e0',
            border: '4px solid #fff',
        },
    },
    thumb: {
        width: 22, // Adjusted thumb size for new height
        height: 22,
        marginTop: 2,
        marginLeft: 2,
        marginRight:2
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: 'grey',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
        position: 'relative', // For pseudo-elements positioning
        '&::before': {
            content: '"Yes"',
            position: 'absolute',
            left: 3,
            top: 3,
            color: '#fff', // Example before state
            borderRadius: '24px 0 0 24px',
            fontWeight:'400',
            fontSize:12
        },
        '&::after': {
            content: '"No"',
            position: 'absolute',
            right: 3,
            top: 3,
            color: '#fff', // Example after state
            borderRadius: '0 24px 24px 0',
            fontWeight:'400',
            fontSize:12
        },
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
