import { format } from "date-fns";
import { RESPONSIBILITY_TYPES } from "../../../../utilities/dictionaryConstants";
import i18n from "../../../../utilities/i18n";
import Decimal from "decimal.js";

/**
 * THis function used to validation process when we submit patient payment or insurance payment
 * @param {*} data 
 * @returns 
 */
export const postPaymentValidations = (data) => {
    let isError = false;
    let errorMessage = "";
    let status = "";
    if (!data.insurancePaymentVariables.payer || data.insurancePaymentVariables.payer == '' || data.insurancePaymentVariables.payer == null) {
        isError = true;
        errorMessage = i18n.t('errorMessages.payer_error');
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }

    if (data.insurancePaymentVariables.level_adjustments > 0 &&
        data.insurancePaymentVariables.adjustment_type.length == 0) {
        isError = true;
        errorMessage = "Please select adjustment type"
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }

    return { isError: isError, errorMessage: errorMessage, status: status }
}


export const postDatagenerator = (data) => {
    return {
        reference_check_number:
            data.insurancePaymentVariables["reference_check_number"],
    }
}

/**
 * This function is used to convert array data to json {} format to get better performance in typing for insurance payment
 * Also this function make procedures to their respective custom claim id user cannot see the procedure they will see custome claim id procdure will display when ever clicking the claim id
 * @param {*} data Here the data is the array of procedure for that insurance
 * @param {*} pk PK contains actual pk of the payment
 * @param {*} insurance_data 
 * @param {*} isReviewed This key will hold wheather the custom claim id is already reviewed or not for a new claim it is always @false if we press save on the procedure modal window it will get @true
 * @param {*} new_entry This is to store the claim is a new entry or already saved one
 * @returns 
 */
export const createJsonData = (data, pk, insurance_data, isReviewed, new_entry) => {
    const groupedData = data.reduce((acc, current) => {
        const { custom_claim_id, patient_id, custom_patient_id, claim_id, allowed_amount, balance, closing_balance, paid_amount, charges, receipts, adjustment_1, adjustment_2, entry_reversed } = current;
        let reversed = new_entry ? false : entry_reversed;
        if (!acc[custom_claim_id]) {
            acc[custom_claim_id] = {
                custom_claim_id,
                claim_id,
                patient_id,
                custom_patient_id,
                pk,
                procedures: [],
                totalAllowedAmount: 0,
                totalPaidAmount: 0,
                totalAdjustment1: 0,
                totalAdjustment2: 0,
                totalCharge: 0,
                totalBalance: 0,
                totalClosingBalance: 0,
                totalReceiptAmount: 0,
                isReviewed: isReviewed ? isReviewed : false
            };
        }

        let dataValues = {
            ...current,
            opening_balance: balance
        }


        acc[custom_claim_id].procedures.push(dataValues);
        acc[custom_claim_id].totalAllowedAmount += parseFloat(allowed_amount ?? 0);
        acc[custom_claim_id].totalPaidAmount += parseFloat(paid_amount ?? 0);

        acc[custom_claim_id].totalCharge = parseFloat(charges) > 0 ? acc[custom_claim_id].totalCharge + parseFloat(charges) : acc[custom_claim_id].totalCharge;

        acc[custom_claim_id].totalBalance += reversed ? CalculateOpeningBalance(data, current) : parseFloat(balance);
        acc[custom_claim_id].totalClosingBalance += reversed ? CalculateClosingBalance(data, current) : parseFloat(closing_balance);

        acc[custom_claim_id].totalReceiptAmount += parseFloat(receipts ?? 0);
        if (adjustment_1 && adjustment_1.length > 0) {
            acc[custom_claim_id].totalAdjustment1 += adjustment_1.reduce((sum, adj) => sum + parseFloat(adj.amount), 0);
        }

        if (adjustment_2 && adjustment_2.length > 0) {
            acc[custom_claim_id].totalAdjustment2 += adjustment_2.reduce((sum, adj) => sum + parseFloat(adj.amount), 0);
        }

        return acc;
    }, {});


    return groupedData;
}


/**
 * This function calculate the closing balance when ever the procedure is revered
 * @param {*} data 
 * @param {*} item 
 * @returns 
 */
const CalculateClosingBalance = (data, item) => {
    let matchingItem = data.find(
        (d) =>
            d.procedure === item.procedure &&
            d.entry_reversed === true &&
            item.charges < 0
    );
    if (matchingItem) {
        return item.closing_balance;
    }
    return 0;
}

/**
 * This function calculate the closing balance when ever the procedure is revered
 * @param {*} data 
 * @param {*} item 
 * @returns 
 */
const CalculateOpeningBalance = (data, item) => {
    let matchingItem = data.find(
        (d) =>
            d.procedure === item.procedure &&
            d.entry_reversed === true &&
            item.charges > 0
    );
    if (matchingItem) {
        return item.balance;
    }
    return 0;
}

/**
 * This function is used to convert array of procedure to json data in patient payment 
 * @param {*} data 
 * @returns 
 */

export const createJsonDataPatientPayment = (data) => {
    const groupedData = data.reduce((acc, current) => {
        let { id, claim_id, custom_claim_id, charges, receipts, opening_balance, apply_payment, closing_balance, apply_adjustments, dos_from, dos_to, save_procedure_id } = current;
        if (!acc[custom_claim_id]) {
            acc[custom_claim_id] = {
                custom_claim_id,
                claim_id,
                procedures: [],
                duplicate_procedures: [],
                id,
                dos_from,
                dos_to,
                edited: false,
                totalAdjustments: 0,
                totalCharges: 0,
                totalBalances: 0,
                totalReceiptAmount: 0,
                totalClosingBalance: 0,
                totalApplyAmount: 0
            };
        }

        let dateValues = {
            ...current,
            apply_payment: apply_payment ? apply_payment : 0,
            apply_adjustments: apply_adjustments ? apply_adjustments : 0,
            closing_balance: closing_balance ? closing_balance : 0,
            save_procedure_id: save_procedure_id ? save_procedure_id : "",
        }

        acc[custom_claim_id].procedures.push(dateValues);
        acc[custom_claim_id].duplicate_procedures.push(dateValues);
        acc[custom_claim_id].totalCharges += parseFloat(charges ?? 0);
        acc[custom_claim_id].totalAdjustments += parseFloat(apply_adjustments ? apply_adjustments : 0);
        acc[custom_claim_id].totalApplyAmount += parseFloat(apply_payment ? apply_payment : 0);
        acc[custom_claim_id].totalBalances += parseFloat(opening_balance ?? 0);
        acc[custom_claim_id].totalReceiptAmount += parseFloat(receipts ?? 0);
        acc[custom_claim_id].totalClosingBalance += parseFloat(closing_balance ? closing_balance : 0);
        return acc;
    }, {})

    return groupedData;
}

/**
 * This function calculate the applied and unapplied amount in patient payment
 * @param {*} data 
 * @param {*} insurancePaymentData 
 * @returns 
 */
export const updateAppliedAndUnappliedAmount = (data, insurancePaymentData) => {
    let unAppliedAmount = 0;
    let appliedAmount = 0;
    let totApplied = new Decimal(0);
    Object.keys(data).forEach((element) => {
        let item = data[element];
        const paidAmount = safeDecimal(item.totalPaidAmountEdited ? item.totalPaidAmountEdited : item.totalPaidAmount ? item.totalPaidAmount : 0 || 0);
        totApplied = totApplied.plus(paidAmount);
    })

    appliedAmount = (totApplied.isNaN() || totApplied === null || totApplied === undefined ? "0.00" : totApplied.toFixed(2));

    if (insurancePaymentData && insurancePaymentData["total_amount"] !== undefined) {
        unAppliedAmount = safeDecimal(insurancePaymentData["total_amount"]).minus(totApplied);
        unAppliedAmount = Object.is(unAppliedAmount.toNumber(), -0) ? 0 : unAppliedAmount.toFixed(2);
    }

    return { unAppliedAmount, appliedAmount }
}

const safeDecimal = (value) => {
    try {
        return new Decimal(value);
    } catch (error) {
        return new Decimal(0); // Fallback to 0 if there's an invalid value (like just a period)
    }
};



/**
 * This function will call when ever we click a custome claim id in insurance
 * it will format the procedures under a custome claim id to json data and display that to modal window
 * @param {*} data 
 * @returns 
 */
export const createProcedureData = (data) => {
    let jsonObject = {};
    let oldResponsibilityObject = {};
    let responsibility_type = {};
    if (data.procedures.length > 0) {
        data.procedures.map((item, index) => {
            let adjustment_code1_data = [];
            let adjustment_code2_data = [];
            let remark_code = [];
            if (item.adjustment_1 && item.adjustment_1.length > 0) {
                for (var code1 in item.adjustment_1) {
                    const code1Data = {
                        ...item.adjustment_1[code1],
                        dropdown_name: item.adjustment_1[code1].label
                    }
                    adjustment_code1_data.push(code1Data)
                }
            }

            if (item.adjustment_2 && item.adjustment_2.length > 0) {
                for (var code2 in item.adjustment_2) {
                    const code2Data = {
                        ...item.adjustment_2[code2],
                        dropdown_name: item.adjustment_2[code2].label
                    }
                    adjustment_code2_data.push(code2Data)
                }
            }
            if (item.remark_code) {
                remark_code = item.remark_code;
            }
            let indexData = {
                payment: data.pk,
                claim: item.claim_id,
                procedure: item.procedure,
                allowed_amount: item.allowed_amount ?? 0,
                paid_amount: item.paid_amount ?? 0,
                receipts: item.receipts ? item.receipts : 0,
                custom_claim_id: item.custom_claim_id,
                dos_from: item.dos_from ? item.dos_from : "",
                remark_code: remark_code,
                opening_balance: item.opening_balance ?? 0,
                closing_balance: item.closing_balance ?? 0,
                patient_id: item.patient_id ? item.patient_id : "",
                charges: item.charges ?? 0,
                copay: item.copay ?? 0,
                co_insurance: item.co_insurance ?? 0,
                deductibles: item.deductibles ?? 0,
                adjustment_code1_data: adjustment_code1_data,
                adjustment_code2_data: adjustment_code2_data,
                responsibility_type: item.responsibility_type,
                pay_procedure_status: item.pay_procedure_status ? item.pay_procedure_status : 0,
                entry_reversed: item.entry_reversed ? item.entry_reversed : false,
                reversed: item.reversed ? item.reversed : false,
                id: item.id ?? "",
                responsibility_list: item.responsibility_list && item.responsibility_list.length > 0 ? item.responsibility_list : RESPONSIBILITY_TYPES,
                claim_status_id: item.claim_status_id,
                claim_sub_status_id: item.claim_sub_status_id,
                previous_responsibility_type: item.previous_responsibility_type ? item.previous_responsibility_type : "",
                previous_claim_status_id: item.previous_claim_status_id ? item.previous_claim_status_id : "",
                previous_claim_sub_status_id: item.previous_claim_sub_status_id ? item.previous_claim_sub_status_id : "",
                moved_to_credit:item.moved_to_credit ? item.moved_to_credit : false,
                paid_by_entity:item.paid_by_entity ? item.paid_by_entity: ""
            };

            if (item.save_procedure_id) {
                indexData = {
                    ...indexData,
                    save_procedure_id: item.save_procedure_id
                }
            }

            if (item.selected_remark_codes) {
                indexData = {
                    ...indexData,
                    selectedRemarkCodes: item.selected_remark_codes,
                }
            }
            jsonObject[`New-${crypto?.randomUUID()}`] = indexData;
            oldResponsibilityObject[index] = item.responsibility_type;
            responsibility_type[index] = item.responsibility_type;
        })


        return { jsonObject, oldResponsibilityObject, responsibility_type };
    }
}


/**
 * This function will call when ever we click a custome claim id in patient payment
 * it will format the procedures under a custome claim id to json data and display that to modal window
 * @param {*} data 
 * @returns 
 */
export const createPatientProcedureData = (data) => {
    let jsonObject = {};
    if (data.procedures.length > 0) {
        data.procedures.map((item) => {
            jsonObject[`New-${crypto?.randomUUID()}`] = item;
        })

        return jsonObject;
    }
}

/**
 * This function is used to format reverse data in insurance payment
 * Here we do all changes for the reverse payment such as negative value convertion
 * We create a new entry for the reverse data here
 * @param {*} data 
 * @param {*} index 
 * @param {*} procedureData 
 * @param {*} id 
 * @returns 
 */
export const createReverseData = (data, index, procedureData, id) => {
    let revexIndex = index + 1
    procedureData[id].entry_reversed = true;
    let adjustment1 = [];
    let adjustment2 = [];
    if (data.adjustment_code1_data && data.adjustment_code1_data.length > 0) {
        for (let code1 in data.adjustment_code1_data) {
            const code1Data = {
                ...data.adjustment_code1_data[code1],
                amount: data.adjustment_code1_data[code1].amount * -1
            }
            adjustment1.push(code1Data);
        }
    }
    if (data.adjustment_code2_data && data.adjustment_code2_data.length > 0) {
        for (let code1 in data.adjustment_code2_data) {
            const code1Data = {
                ...data.adjustment_code2_data[code1],
                amount: data.adjustment_code2_data[code1].amount * -1
            }
            adjustment2.push(code1Data);
        }
    }

    let editedData = {
        "payment": data.payment,
        "claim": data.claim,
        "procedure": data.procedure,
        "dos_from": data.dos_from,
        "custom_claim_id": data.custom_claim_id,
        "allowed_amount": data.allowed_amount ? data.allowed_amount * -1 : "0.00",
        "paid_amount": data.paid_amount ? data.paid_amount * -1 : "0.00",
        "remark_code": data.remark_code,
        "opening_balance": data.closing_balance ?? "0.00",
        "closing_balance": data.opening_balance ?? "0.00",
        "charges": data.charges ? data.charges * -1 : "0.00",
        "adjustment_code1_data": adjustment1,
        "adjustment_code2_data": adjustment2,
        "copay": data.copay ? data.copay * -1 : "0.00",
        "co_insurance": data.co_insurance ? data.co_insurance * -1 : "0.00",
        "deductibles": data.deductibles ? data.deductibles * -1 : "0.00",
        "pay_procedure_status": 1,
        "selectedRemarkCodes": data.selectedRemarkCodes,
        "id": data.id,
        "entry_reversed": true,
        "reversed": true,
        "receipts": data.receipts ? data.receipts * -1 : "0.00",
        "responsibility_list": data.responsibility_list ? data.responsibility_list : RESPONSIBILITY_TYPES,
    }

    editedData = {
        [`New-${crypto?.randomUUID()}`]: {
            ...editedData,
            "responsibility_type": data.previous_responsibility_type ? data.previous_responsibility_type : "",
            "claim_status_id": data.previous_claim_status_id ? data.previous_claim_status_id : "",
            "claim_sub_status_id": data.previous_claim_sub_status_id ? data.previous_claim_sub_status_id : "",
        }
    }

    const entries = Object.entries(procedureData);
    entries.splice(revexIndex, 0, ...Object.entries(editedData));
    const updatedData = Object.fromEntries(entries);
    return updatedData;
}

function decimalConverter(value) {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
}

/**
 * This function is used to identify the changes maded in insurance payment on blurer
 * It is used to change the responsibility and claim status and claim sub status also closing balance
 * @param {*} data 
 * @param {*} field 
 * @param {*} oldResponsibility 
 * @param {*} responsibility_type 
 * @returns 
 */
export const checkForDataChanges = (data, field, oldResponsibility, responsibility_type) => {
    const paymentPostStatus = Boolean(data["save_procedure_id"]);
    const paid_amount = decimalConverter(data["paid_amount"]);
    const allowed_amount = decimalConverter(data["allowed_amount"]);
    const opening_balance = decimalConverter(data["opening_balance"]);
    const totalAdjustment1Amount = decimalConverter(data.adjustment_code1_data?.reduce((total, item) => total + (Number(item?.amount) || 0), 0));

    const closing_balance = Number((opening_balance - paid_amount - totalAdjustment1Amount).toFixed(2));
    const hasAdjustmentCode2 = data["adjustment_code2_data"]?.length > 0;
    const adjustmentCodeExists = checkAdjustmentCode(data["adjustment_code2_data"]);

    const isAllowedAmountPaid = paid_amount === allowed_amount && allowed_amount !== 0;
    const isClosingBalanceZero = closing_balance <= 0;

    const resolveResponsibility = () => {
        if (hasAdjustmentCode2) {
            return adjustmentCodeExists ? findResponsibilityByID(data, oldResponsibility) : handleResponsibilityType(data, responsibility_type, oldResponsibility, opening_balance);
        }
        return oldResponsibility;
    };

    if (!paymentPostStatus) {
        switch (field) {
            case "allowed_amount":
                if (isAllowedAmountPaid) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                }
                return { responsibilityID: resolveResponsibility(), closing_balance };

            case "paid_amount":
                if (isAllowedAmountPaid && !hasAdjustmentCode2) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                }
                if (!hasAdjustmentCode2 && isClosingBalanceZero) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                }
                return { responsibilityID: resolveResponsibility(), closing_balance };

            case "adjustment_amount1":
            case "adjustment_code1_data":
                if (isAllowedAmountPaid && !hasAdjustmentCode2) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                }
                if (!hasAdjustmentCode2 && isClosingBalanceZero) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                }
                return { responsibilityID: resolveResponsibility(), closing_balance };

            case "adjustment_amount2":
            case "adjustment_code2_data":
                if (!hasAdjustmentCode2 && isClosingBalanceZero) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                }
                if (isAllowedAmountPaid && !hasAdjustmentCode2) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                }
                return { responsibilityID: resolveResponsibility(), closing_balance };
        }
    } else {
        switch (field) {
            case "paid_amount":
                if (isAllowedAmountPaid && !hasAdjustmentCode2) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                }
                else if (!hasAdjustmentCode2 && isClosingBalanceZero) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                } else if (data["adjustment_code2_data"].length > 0) {
                    const exist = checkAdjustmentCode(data["adjustment_code2_data"]);
                    if (exist) {
                        return { responsibilityID: findResponsibilityByID(data, data["previous_responsibility_type"]), closing_balance }
                    }
                    else {
                        return { responsibilityID: handleResponsibilityType(data, data["previous_responsibility_type"], oldResponsibility, opening_balance), closing_balance }
                    }
                } else if (!isClosingBalanceZero && !hasAdjustmentCode2) {
                    return { responsibilityID: findResponsibilityByID(data, data["previous_responsibility_type"]), closing_balance }
                }
                return { responsibilityID: data["previous_responsibility_type"], closing_balance };
            case "adjustment_amount1":
            case "adjustment_code1_data":
                if (!hasAdjustmentCode2 && isClosingBalanceZero) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                } else if (isAllowedAmountPaid && !hasAdjustmentCode2) {
                    return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                } else if (data["adjustment_code2_data"].length > 0) {
                    const exist = checkAdjustmentCode(data["adjustment_code2_data"]);
                    if (exist) {
                        return { responsibilityID: findResponsibilityByID(data, data["previous_responsibility_type"]), closing_balance }
                    }
                    else {
                        return { responsibilityID: handleResponsibilityType(data, data["previous_responsibility_type"], oldResponsibility, opening_balance), closing_balance }
                    }
                } else if (!isClosingBalanceZero && !hasAdjustmentCode2) {
                    return { responsibilityID: findResponsibilityByID(data, data["previous_responsibility_type"]), closing_balance }
                }
                return { responsibilityID: data["previous_responsibility_type"], closing_balance };
            case "adjustment_amount2":
            case "adjustment_code2_data":
                if (data["adjustment_code2_data"].length > 0) {
                    if (data["previous_responsibility_type"] !== oldResponsibility) {
                        const exist = checkAdjustmentCode(data["adjustment_code2_data"]);
                        if (exist) {
                            return { responsibilityID: findResponsibilityByID(data, data["previous_responsibility_type"]), closing_balance }
                        }
                        else {
                            return { responsibilityID: handleResponsibilityType(data, data["previous_responsibility_type"], oldResponsibility, opening_balance), closing_balance }
                        }
                    } else {
                        return { responsibilityID: handleResponsibilityType(data, data["previous_responsibility_type"], oldResponsibility, opening_balance), closing_balance }
                    }
                } else {
                    if (isAllowedAmountPaid) {
                        return { responsibilityID: findResponsibilityByName(data, 'Paid'), closing_balance };
                    } else {
                        return { responsibilityID: data["previous_responsibility_type"], closing_balance };
                    }
                }
        }
    }
    return { responsibilityID: oldResponsibility, closing_balance };
};


export function checkAdjustmentCode(procedure) {
    let allowedLabels = ["PR-1", "PR-2", "PR-3"];
    let adjustmentCodes = procedure;
    let length = adjustmentCodes.length;

    if (adjustmentCodes && Array.isArray(adjustmentCodes)) {
        // Iterate over the adjustmentCodes array in reverse to safely splice
        for (let i = adjustmentCodes.length - 1; i >= 0; i--) {
            if (allowedLabels.includes(adjustmentCodes[i].label)) {
                length--;
            }
        }
    }
    if (length == 0) {
        return false;
    } else {
        return true;
    }
}


export function checkAdjustmentCodeDenialId(procedure) {
    const allowedLabels = ["PR-1", "PR-2", "PR-3"];
    let filteredItems = procedure.filter(item => !allowedLabels.includes(item.label));

    // If no items match, return null
    if (filteredItems.length === 0) return null;

    // Get the last item from filtered list and return its claim_sub_status id
    const lastItem = filteredItems[filteredItems.length - 1];

    return lastItem.claim_sub_status.length > 0
        ? lastItem.claim_sub_status[0].id
        : "";
}

const findResponsibilityByName = (data, name) => {
    return data["responsibility_list"]?.find(item => item.name === name)?.id;
};

const findResponsibilityByID = (data, id) => {
    return data["responsibility_list"]?.find(item => item.id === id)?.id;
};

const handleResponsibilityType = (data, responsibility_type, oldResponsibility, opening_balance) => {
    switch (responsibility_type) {
        case 1: // Secondary
            return findResponsibilityByName(data, 'Secondary') || findResponsibilityByName(data, 'Patient');
        case 2: // Tertiary
            return findResponsibilityByName(data, 'Tertiary') || findResponsibilityByName(data, 'Patient');
        case 3: // Patient
        case 4:
            return findResponsibilityByName(data, 'Patient');
        case 5: // Special case
            return opening_balance <= 0 ? 5 : (findResponsibilityByName(data, 'Secondary') || findResponsibilityByName(data, 'Patient'));
        default:
            return oldResponsibility;
    }
};


/**
 * This function is used for identify errors in insuranc payment procedure level for all fields
 * @param {*} data 
 * @param {*} procedureError 
 * @returns 
 */
export const checkForErrors = (data, procedureError) => {

    let errors = { ...procedureError };
    let paid_amount = Number(Number(data.paid_amount)?.toFixed(2)) || 0;
    let charges = Number(Number(data.charges)?.toFixed(2)) || 0;
    let allowed_amount = Number(Number(data.allowed_amount)?.toFixed(2)) || 0;
    let discount = charges - allowed_amount;
    let opening_balance = Number(Number(data.opening_balance)?.toFixed(2)) || 0;
    let totalAdjustment1Amount = data.adjustment_code1_data?.reduce((sum, adjustmentItem) => {
        // CON-253 Should not be considered as discount
        if (adjustmentItem.label?.toLowerCase() !== "co-253") {
            return sum + Number(adjustmentItem?.amount);
        } else {
            return sum;
        }
    }, 0) || 0;


    let totalAdjustmentAmount2 = data.adjustment_code2_data?.reduce((sum, adjustmentItem) => sum + Number(adjustmentItem?.amount), 0) || 0;

    if (allowed_amount > opening_balance) {
        errors["allowed_amount"] = 'Allowed amount cannot be greater than opening balance amount.';
    } else {
        if (errors && Object.prototype.hasOwnProperty.call(errors, 'allowed_amount')) {
            delete errors.allowed_amount;
        }
    }

    if (paid_amount > charges || paid_amount > allowed_amount) {
        errors["paid_amount"] = 'Paid amount cannot be greater than charges amount or allowed amount.';
    }
    else {
        if (errors && Object.prototype.hasOwnProperty.call(errors, 'paid_amount')) {
            delete errors.paid_amount;
        }
    }

    if (Number(Number(totalAdjustment1Amount)?.toFixed(2)) > Number(Number(discount)?.toFixed(2))) {
        errors["adjustment_amount1"] = {
            ...data.adjustment_code1_data.reduce((errors, adjustmentItem) => {
                if (adjustmentItem.label?.toLowerCase() !== "co-253") {
                    errors[adjustmentItem.id] = {
                        id: adjustmentItem.id,
                        message: 'Total Adjustment 1 amount should not be greater than Charge amount - Allowed amount.',
                        label: adjustmentItem?.label
                    };
                }
                return errors;

            }, {})
        }
    } else {
        if (errors["adjustment_amount1"]) {
            errors["adjustment_amount1"] = {
                ...data.adjustment_code1_data.reduce((errors, adjustmentItem) => {
                    if (adjustmentItem.label?.toLowerCase() !== "co-253") {
                        delete errors[adjustmentItem.id]
                    }
                    return errors;

                }, {})
            }
        }
    }

    if (Number(Number(totalAdjustmentAmount2)?.toFixed(2)) > Number((allowed_amount - paid_amount)?.toFixed(2))) {
        errors["adjustment_amount2"] = {
            ...data.adjustment_code2_data.reduce((errors, adjustmentItem) => {
                errors[adjustmentItem.id] = {
                    id: adjustmentItem.id,
                    message: 'Total Adjustment 2 amount should not be greater than Allowed amount - Paid amount.',
                    label: adjustmentItem?.label
                };
                return errors;
            }, {})
        }
    } else {
        if (errors["adjustment_amount2"]) {
            errors["adjustment_amount2"] = {
                ...data.adjustment_code2_data.reduce((errors, adjustmentItem) => {
                    delete errors[adjustmentItem.id]
                    return errors;
                }, {})
            }
        }
    }
    return errors;
}

/**
 * function is used to create form data for patient procedure level save functionality
 * @param {*} patientClaims 
 * @returns 
 */
export const generatePatientSaveData = async (patientClaims) => {
    let saveData = [];
    Object.keys(patientClaims).forEach(ele1 => {
        let item = patientClaims[ele1];
        if (item.procedures.length > 0) {
            item.procedures.forEach(ele2 => {
                let data = {
                    apply_payment: ele2.apply_payment === "" ? "0.00" : ele2.apply_payment,
                    "apply_adjustments": ele2.apply_adjustments === "" ? "0.00" : ele2.apply_adjustments,
                    "claim": ele2.claim_id,
                    "procedure": ele2.id ? ele2.id : ele2.procedure_id ? ele2.procedure_id : "",
                    "opening_balance": ele2.opening_balance,
                    "closing_balance": ele2.closing_balance,
                    "responsibility_type": ele2.responsibility_type,
                    "claim_status_id": ele2.claim_status_id,
                    "claim_sub_status_id": ele2.claim_sub_status_id,
                    "save_procedure_id": ele2.save_procedure_id ? ele2.save_procedure_id : ''
                }
                saveData.push(data);
            })
        }
    })

    return saveData;
}


/**
 * function is used to create form data for insurance procedure level save functionality
 * @param {*} claimListData 
 * @param {*} insurancePaymentData 
 * @returns 
 */
export const generateSaveData = async (claimListData, insurancePaymentData) => {
    let saveData = [];
    let pk = ""
    Object.keys(claimListData).forEach(ele1 => {
        let item = claimListData[ele1];
        if (item.procedures.length > 0) {
            item.procedures.forEach(ele2 => {
                let adjustment1_data = [];
                let adjustment2_data = [];
                if (ele2.adjustment_1 && ele2.adjustment_1.length > 0) {
                    for (var code1 in ele2.adjustment_1) {
                        const code1Data = {
                            code: ele2.adjustment_1[code1].label,
                            amount: parseFloat(ele2.adjustment_1[code1].amount),
                            id: ele2.adjustment_1[code1].id
                        }
                        adjustment1_data.push(code1Data)
                    }
                }

                if (ele2.adjustment_2 && ele2.adjustment_2.length > 0) {
                    for (var code2 in ele2.adjustment_2) {
                        const code1Data = {
                            code: ele2.adjustment_2[code2].label,
                            amount: parseFloat(ele2.adjustment_2[code2].amount),
                            id: ele2.adjustment_2[code2].id
                        }
                        adjustment2_data.push(code1Data)
                    }
                }

                let data = {
                    ...ele2,
                    copay: ele2.copay && ele2.copay != "" ? ele2.copay : 0.00,
                    claim: ele2.claim ? ele2.claim : ele2.claim_id ? ele2.claim_id : "",
                    co_insurance: ele2.co_insurance && ele2.co_insurance != "" ? ele2.co_insurance : 0.00,
                    deductibles: ele2.deductibles && ele2.deductibles != "" ? ele2.deductibles : 0.00,
                    save_procedure_id: ele2.save_procedure_id ?? "",
                    allowed_amount: ele2.allowed_amount ? ele2.allowed_amount : 0.00,
                    paid_amount: ele2.paid_amount ? ele2.paid_amount : 0.00,
                    procedure: ele2.id ?? "",
                    adjustments: {
                        adjustment1: adjustment1_data,
                        adjustment2: adjustment2_data,
                    },
                    payment: insurancePaymentData.id
                }
                delete data["custom_claim_id"];
                delete data["id"];
                delete data["receipts"];
                delete data["responsibility_list"];
                delete data["dos_from"];
                delete data["patient_id"];
                delete data["adjustment_1"];
                delete data["adjustment_2"];
                delete data["previous_responsibility_type"];
                delete data["previous_claim_status_id"];
                delete data["previous_claim_sub_status_id"];
                delete data["balance"];
                delete data["responsibility_type_name"];
                delete data["selected_remark_codes"];
                delete data["claim_id"];
                delete data["reversed"];
                delete data["custom_patient_id"];
                saveData.push(data);
                pk = insurancePaymentData.id;
            })
        }
    })

    return { pk, saveData };
}

/**
 * This function validate patient level data errors on save of that payment 
 * @param {*} patientClaims 
 * @returns 
 */
export const checkForValidationErrorPatient = (patientClaims) => {
    let validationError = [];
    let isError = false;

    Object.keys(patientClaims).forEach((item) => {
        let procedureData = patientClaims[item].procedures;
        procedureData.forEach((item, index) => {
            if (item?.responsibility_type == null) {
                validationError.push({
                    error: i18n.t(
                        "payments.post_payments.insurancePayment.paymenMessages.emptyPaymentResponsibleType"
                    ) + ` ${item.custom_claim_id} procedure line ${index + 1}`
                })
            }
        })
    })

    if (validationError.length > 0) {
        isError = true;
    }
    else {
        isError = false;
    }
    return { isError, validationError };
}

/**
 * This function validate insurance level data errors on save of that payment 
 * @param {*} claimList 
 * @param {*} insurancePaymentData 
 * @returns 
 */
export const checkForValidationError = (claimList, insurancePaymentData) => {
    let validationError = [];
    let isError = false;
    Object.keys(claimList).forEach((item) => {

        let procedureData = claimList[item].procedures;

        procedureData.forEach((item, index) => {

            let paid_amount = parseFloat(item.paid_amount);
            let allowed_amount = parseFloat(item.allowed_amount);
            let copay = parseFloat(item.copay ? item.copay : 0);
            let co_insurance = parseFloat(item.co_insurance ? item.co_insurance : 0);
            let deductibles = parseFloat(item.deductibles ? item.deductibles : 0);

            let amount1 = parseFloat(allowed_amount - paid_amount);

            let amount2 = deductibles + co_insurance + copay;

            if (item?.responsibility_type == null) {
                validationError.push({
                    error: i18n.t(
                        "payments.post_payments.insurancePayment.paymenMessages.emptyPaymentResponsibleType"
                    ) + ` ${item.custom_claim_id} procedure line ${index + 1}`
                })
            }

            const date1 = new Date(insurancePaymentData.payment_date);
            const date2 = new Date(item.dos_from);
            const isDate2GreaterThanDate1 = date2 < date1;
            if (!isDate2GreaterThanDate1) {
                validationError.push({
                    error: i18n.t(
                        "payments.post_payments.insurancePayment.paymenMessages.paymentDateAlert"
                    ) + ` ${item.custom_claim_id} procedure line ${index + 1}`
                })
            }

            if (parseFloat(amount2.toFixed(2)) > parseFloat(amount1.toFixed(2)) && amount1 != 0 && !item?.reversed) {
                validationError.push({
                    error: i18n.t(
                        "payments.post_payments.insurancePayment.paymenMessages.copayValidation"
                    ) + `${item.custom_claim_id} procedure line ${index + 1}`
                })

            }

            if (parseFloat(item.paid_amount) > 0 && parseFloat(item.allowed_amount) > 0) {
                if (
                    parseFloat(item.paid_amount) > parseFloat(item.allowed_amount)) {
                    validationError.push({
                        error: i18n.t(
                            "payments.post_payments.insurancePayment.paymenMessages.paidAmountValidation"
                        ) + ` ${item.custom_claim_id} procedure line ${index + 1}`
                    })
                }
            }

            if (!item.paid_by_entity) {
                validationError.push({
                    error: i18n.t(
                        "payments.post_payments.insurancePayment.paymenMessages.emptyPaidByEntity"
                    )  + ` ${item.custom_claim_id} procedure line ${index + 1}`
                })  
            }

        })

    })

    if (validationError.length > 0) {
        isError = true;
    }
    else {
        isError = false;
    }
    return { isError, validationError };
}

/**
 * This function will trigger when ever we click on the modal save button
 * Here we will store the new value to the main patientClaim redux state and update it 
 * @param {*} data 
 * @returns 
 */
export const generateTablePatientData = (data) => {
    let totalChargeEdited = 0;
    let totalReceiptEdited = 0;
    let totalBalanceEdited = 0;
    let totalAdjustmentsEdited = 0;
    let totalApplyAmount = 0;
    let totalClosingBalanceEdited = 0;
    let procedures = [];
    Object.keys(data).forEach((current) => {
        const { apply_payment, apply_adjustments, closing_balance, opening_balance, charges, receipts } = data[current];
        totalChargeEdited += parseFloat(charges ?? 0);
        totalReceiptEdited += parseFloat(receipts ?? 0);
        totalBalanceEdited += parseFloat(opening_balance ?? 0);
        totalAdjustmentsEdited += parseFloat(apply_adjustments ?? 0);
        totalApplyAmount += parseFloat(apply_payment ?? 0);
        totalClosingBalanceEdited += parseFloat(closing_balance ?? 0);
        procedures.push(data[current])
    }, {});
    return {
        totalChargeEdited, totalReceiptEdited, totalBalanceEdited, totalAdjustmentsEdited, totalApplyAmount, totalClosingBalanceEdited, procedures
    };
}

/**
 * This function will trigger when ever we click on the modal save button
 * Here we will store the new value to the main claimList redux state and update it 
 * @param {*} data 
 * @returns 
 */
export const generateTableData = (data) => {
    let totalAllowedAmountEdited = 0;
    let totalPaidAmountEdited = 0;
    let totalChargeEdited = 0;
    let totalBalanceEdited = 0;
    let totalClosingBalanceEdited = 0;
    let totalReceiptEdited = 0;
    let totalAdjustment1Edited = 0;
    let totalAdjustment2Edited = 0;
    let procedures = [];
    Object.keys(data).forEach((current) => {
        const { allowed_amount, opening_balance, paid_amount, receipts, charges, closing_balance, adjustment_code1_data, adjustment_code2_data, claim,custom_claim_id, entry_reversed } = data[current];
        let dataValues = {
            ...data[current],
            claim_id: claim,
            adjustment_1: adjustment_code1_data,
            adjustment_2: adjustment_code2_data
        }

        delete dataValues["adjustment_code1_data"];
        delete dataValues["adjustment_code2_data"];
        procedures.push(dataValues)
        totalAllowedAmountEdited += parseFloat(allowed_amount ?? 0);
        totalPaidAmountEdited += parseFloat(paid_amount ?? 0);
        totalChargeEdited = parseFloat(charges) > 0 ? totalChargeEdited + parseFloat(charges) : totalChargeEdited;

        totalBalanceEdited += (entry_reversed !== undefined) && entry_reversed == true ? calculateBalance(data,custom_claim_id,opening_balance,charges) : parseFloat(opening_balance);
        totalClosingBalanceEdited += (entry_reversed !== undefined) && entry_reversed == true ? calculateClosingBalance(data,custom_claim_id,closing_balance,charges) : parseFloat(closing_balance);

        totalReceiptEdited += parseFloat(receipts ?? 0);
        if (adjustment_code1_data && adjustment_code1_data.length > 0) {
            totalAdjustment1Edited += adjustment_code1_data.reduce((sum, adj) => sum + parseFloat(adj.amount), 0);
        }

        if (adjustment_code2_data && adjustment_code2_data.length > 0) {
            totalAdjustment2Edited += adjustment_code2_data.reduce((sum, adj) => sum + parseFloat(adj.amount), 0);
        }
    }, {});
    return {
        totalAdjustment1Edited, totalAdjustment2Edited, totalAllowedAmountEdited, totalBalanceEdited, totalChargeEdited,
        totalClosingBalanceEdited, totalPaidAmountEdited, totalReceiptEdited, procedures
    };


    function calculateBalance(data,claimId,opening_balance,charges){
        let matchingItem =  Object.keys(data).find(
            (d) =>
                data[d].custom_claim_id === claimId &&
                data[d].entry_reversed === true &&
                charges > 0
        );
        if (matchingItem) {
            return opening_balance;
        }
        return 0;
    }

    function calculateClosingBalance(data,claimId,closing_balance,charges){
        let matchingItem =  Object.keys(data).find(
            (d) =>
                data[d].custom_claim_id === claimId &&
                data[d].entry_reversed === true &&
                charges < 0
        );
        if (matchingItem) {
            return closing_balance;
        }
        return 0;
    }
}

export const formatDate = (date) => {
    return date instanceof Date ? format(date, "yyyy-MM-dd") : "";
};


export const getSumAppliedUnApplied = (data, patientDetails) => {
    let unApplied = 0;
    let sumApplied = 0;
    let alreadyApplied = 0;
    if (isNaN(data.balance_amount - patientDetails.sumApplied)) {
        unApplied = patientDetails.unAppliedAmount;
    } else {
        unApplied = data.balance_amount - patientDetails.sumApplied;
    }

    if (isNaN(parseFloat(data?.amount) - parseFloat(data.balance_amount))) {
        sumApplied = patientDetails.sumApplied
    }
    else {
        sumApplied = parseFloat(data?.amount) - parseFloat(data.balance_amount);
    }

    if (isNaN(parseFloat(data?.amount) - parseFloat(data.balance_amount))) {
        alreadyApplied = patientDetails.alreadyApplied
    } else {
        alreadyApplied = parseFloat(data?.amount) - parseFloat(data.balance_amount);
    }

    return { unApplied, sumApplied, alreadyApplied }
}

export function isValidDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject instanceof Date && !isNaN(dateObject);
}


export const sortByDate = (sortOrder, obj) => {
    const claimsArray = Object.values(obj);
    claimsArray.sort((a, b) => {
        const dateA = new Date(a.dos_from);
        const dateB = new Date(b.dos_from);

        return sortOrder === "balanceUnderPatientNewestFirst"
            ? dateB - dateA
            : dateA - dateB;
    });

    // Return sorted claim IDs
    return claimsArray.map(item => item.custom_claim_id);
};